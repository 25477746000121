import "../main.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { EnvironmentBanner, MesoKitContext } from "@tigris/mesokit";
import { Scope } from "@sentry/react";
import { Sentry, Posthog } from "@tigris/common";
import { toast } from "sonner";
import { prepare, prepareMSW } from "./utils/boot";
import { RouterContextProvider } from "./contexts/RouterContextProvider";
import { routes } from "./routes";
import { OnboardingAppForTransfer } from "./components/OnboardingAppForTransfer";
import { ErrorCard } from "./components/ErrorCard";
import { ToggleDevMode } from "./dev/ToggleDevMode";
import { Routes } from "./types";

const { rootElement } = prepare();

(async () => {
  await prepareMSW();

  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={<ErrorCard />}
        beforeCapture={(scope: Scope) => scope.setLevel("warning")}
      >
        <RouterContextProvider
          routes={routes}
          initialRoute={{ pathname: Routes.Root }}
        >
          <MesoKitContext.Provider
            value={{ sentry: Sentry, toast, posthog: Posthog }}
          >
            <OnboardingAppForTransfer />
          </MesoKitContext.Provider>
        </RouterContextProvider>
      </Sentry.ErrorBoundary>
      <ToggleDevMode />
      <div className="fixed top-4 left-0 flex w-full justify-center">
        <EnvironmentBanner />
      </div>
    </React.StrictMode>,
  );
})();
