import { Text } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";

import { motion, Variants } from "framer-motion";

const containerClasses = twMerge(
  // Layout
  "fixed flex w-[22rem] max-w-[90%] flex-col items-center gap-2 py-6 px-8 text-center",
  // Visual
  "bg-white text-black shadow-lg rounded-xl dark:bg-neutral-800",
);

const animation: Variants = {
  initial: {
    opacity: 0,
    y: 90,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

export const ErrorCard = () => {
  return (
    <div className="flex h-full items-center justify-center">
      <motion.div
        key="Toast"
        data-testid="toast"
        className={containerClasses}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={animation}
      >
        <div className="text-3xl">
          <FontAwesomeIcon icon={faWarning} className="text-[#e1af4f]" />
        </div>
        <Text className="text-sm font-bold text-neutral-800">
          Something went wrong
        </Text>
        <Text>
          Please try again. If the issue persists, contact{" "}
          <a href="mailto:support@meso.network" className="underline">
            Meso support
          </a>
          .
        </Text>
      </motion.div>
    </div>
  );
};
