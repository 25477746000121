import {
  getUS,
  Input,
  nonUSCountries,
  PhoneCountrySelect,
  sortedCountries,
  AuthCodeRef,
  ErrorMessages,
  CustomToastError,
  AuthCode,
  UnsupportedPhoneNumberToastError,
} from "@tigris/mesokit";
import {
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  Country,
  Value,
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
// eslint-disable-next-line no-duplicate-imports
import PhoneInput from "react-phone-number-input";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";

import { useRouter } from "../hooks/useRouter";
import {
  CountryCodeAlpha2,
  OnboardingAppRenderContext,
  Routes,
} from "../types";
import { twMerge } from "tailwind-merge";
import { faArrowRight, faMessageSms } from "@fortawesome/pro-solid-svg-icons";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { AutoFocusRef } from "../utils/autoFocusRef";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Posthog, Sentry, TelemetryEvents } from "@tigris/common";
import { TwoFactorMethod } from "../generated/sdk";

const TOAST_ID = "PhoneEntry";
const FORM_ID = TOAST_ID;
const INPUT_ID = "phoneInput";

// We need to do this so we can wrap our `Input` with `grow` class since we cannot control the DOM hierarchy of react-phone-number-input
const PositionallyWrappedInput = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => (
    <div className="grow">
      <Input ref={ref} {...props} />
    </div>
  ),
);

export const PhoneEntry = ({ resendSeconds }: { resendSeconds?: number }) => {
  const { navigate } = useRouter();
  const {
    api: {
      resolveAddPhoneNumber,
      resolveSend2FACode,
      resolveVerifyPhoneNumber,
      resolveUser,
    },
    session,
  } = useApi();
  const {
    updateUser,
    user,
    hasPasskey,
    renderContext,
    browserSupportsWebAuthn,
  } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAuthCode, setIsLoadingAuthCode] = useState(false);
  const [show2Fa, setShow2Fa] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<{
    value?: Value;
    isValid: boolean;
    isDirty: boolean;
  }>({
    value: "",
    isValid: false,
    isDirty: false,
  });
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const authCodeRef = useRef<AuthCodeRef>(null);

  const handleAddPhoneNumber = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const addPhoneNumberResult = await resolveAddPhoneNumber({
        input: { phoneNumber: phoneNumber.value! },
      });

      setIsLoading(false);

      if (addPhoneNumberResult.isErr()) {
        toast.error(addPhoneNumberResult.error, { id: TOAST_ID });
      } else {
        updateUser({ phoneNumber: formatPhoneNumber(phoneNumber.value!) });
        Posthog.capture(TelemetryEvents.onboardingPhoneNumberAdd);
        setShow2Fa(true);
        setTimeout(() => authCodeRef.current?.focus(), 300);
      }
    },
    [phoneNumber.value, resolveAddPhoneNumber, updateUser],
  );

  const handleError = useCallback(
    (error: string | React.ReactNode) => {
      setIsLoadingAuthCode(false);
      authCodeRef.current?.clear();
      toast.error(error, {
        id: TOAST_ID,
      });

      setTimeout(() => {
        authCodeRef.current?.focus();
      });
    },
    [setIsLoadingAuthCode, authCodeRef],
  );

  const verifyPhoneChallenge = useCallback(
    async (verificationCode: string) => {
      setIsLoadingAuthCode(true);

      const verifyPhoneNumberResult = await resolveVerifyPhoneNumber({
        input: { verificationCode },
      });

      if (verifyPhoneNumberResult.isErr()) {
        if (
          verifyPhoneNumberResult.error ===
          ErrorMessages.twoFactorAuth.DUPLICATE_PHONE_NUMBER_ERROR
        ) {
          handleError(
            <CustomToastError
              title=""
              body={
                <div>
                  Looks like you already have an account. Try{" "}
                  <a
                    href="https://account.meso.network"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    logging in
                  </a>
                  .
                </div>
              }
            />,
          );
          Posthog.capture(TelemetryEvents.onboardingDuplicatePhoneNumber);
          return;
        }
        handleError(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
        return;
      }

      // Once we have verified the user's 2nd factor, we can get the user ID from the backend to store in our context
      const userResult = await resolveUser();

      if (userResult.isErr()) {
        handleError(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
        return;
      }

      const updatedUser = userResult.value;
      updateUser({ id: updatedUser.id });
      Posthog.capture(TelemetryEvents.onboardingPhoneNumberVerify);

      if (
        session?.passkeyOnboardingEnabled &&
        !hasPasskey &&
        renderContext === OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER &&
        browserSupportsWebAuthn
      ) {
        navigate(Routes.RegisterPasskey);
      } else {
        navigate(Routes.BasicInfoOverview);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAuthCodeChange = useMemo(
    () => async (result: string) => {
      if (result.length === 6) {
        await verifyPhoneChallenge(result);
      }
    },
    [verifyPhoneChallenge],
  );

  const sendCode = useCallback(async () => {
    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      setIsLoadingAuthCode(false);
      authCodeRef.current?.clear();
      if (
        send2FACodeResult.error ===
        ErrorMessages.twoFactorAuth.UNSUPPORTED_PHONE_NUMBER_ERROR
      ) {
        toast.error(<UnsupportedPhoneNumberToastError />, { id: TOAST_ID });
        Posthog.capture(TelemetryEvents.onboardingUnsupportedPhoneNumber);
        return;
      }
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
    } else {
      setIsLoadingAuthCode(false);
      setTimeout(() => authCodeRef.current?.focus());
    }
  }, [resolveSend2FACode]);

  useEffect(() => {
    if (
      !user.residenceCountry ||
      !Object.values(CountryCodeAlpha2).some(
        (country) => country === user.residenceCountry,
      )
    ) {
      Sentry?.captureMessage(
        ErrorMessages.debug.UNEXPECTED_USER_RESIDENCE_COUNTRY,
        {
          level: "warning",
          extra: { userResidenceCountry: user.residenceCountry },
        },
      );
    }
  }, [user.residenceCountry]);

  return (
    <AnimationContainer onAnimationComplete={AutoFocusRef(phoneInputRef)}>
      <form
        id={FORM_ID}
        name={FORM_ID}
        onSubmit={handleAddPhoneNumber}
        className="onboarding-inner-content"
      >
        <Heading
          title="Two-Factor Authentication"
          subtitle="Two-factor authentication adds an extra layer of security to your
        account."
        />
        <section className="flex flex-col gap-1">
          <div className="relative flex items-center">
            <PhoneInput
              name={INPUT_ID}
              id={INPUT_ID}
              className="flex w-full"
              placeholder="Your Phone Number"
              defaultCountry={
                (user.residenceCountry as Country) ?? CountryCodeAlpha2.US
              }
              smartCaret={true}
              value={phoneNumber.value}
              inputComponent={PositionallyWrappedInput}
              countryCallingCodeEditable={false}
              countrySelectComponent={PhoneCountrySelect}
              countrySelectProps={{
                static: !session!.euEnabled,
                countries:
                  user.residenceCountry === CountryCodeAlpha2.US
                    ? [getUS()]
                    : sortedCountries(nonUSCountries),
              }}
              international={false}
              limitMaxLength={true}
              data-testid="phone-input"
              isValid={
                phoneNumber.isValid ||
                !(phoneNumber.isDirty && !phoneNumber.isValid)
              }
              // @ts-expect-error: `react-phone-number-input` does not provide a type declaration for this, but it resolves to an `HTMLInputElement`.
              ref={phoneInputRef}
              onChange={(value) => {
                setPhoneNumber((state) => {
                  const isValid =
                    value === "" || value === undefined
                      ? false
                      : isValidPhoneNumber(value as string);
                  const isDirty =
                    state.isValid === false && state.isDirty === true
                      ? false
                      : (value && value.length === 12) ||
                        (!state.isDirty && isValid);

                  return {
                    value,
                    isValid,
                    isDirty,
                  };
                });
              }}
            />

            <button
              type="submit"
              tabIndex={0}
              className={twMerge([
                "bg-primary dark:bg-primary-light absolute right-1 h-3/4 w-10 rounded-[12px] font-bold text-white transition duration-100 hover:opacity-90",
                isLoading || !phoneNumber.isValid
                  ? "cursor-not-allowed bg-neutral-400 hover:bg-neutral-400 dark:bg-neutral-600 dark:text-neutral-400 dark:hover:bg-neutral-600"
                  : "scale-100 active:scale-95",
              ])}
              data-testid="phone-input:submit"
              disabled={isLoading || !phoneNumber.isValid}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="hover:opacity-70 dark:text-white"
              />
            </button>
          </div>
        </section>
        {show2Fa && (
          <section
            className="flex flex-col gap-4"
            data-testid="phone-input:2FA"
          >
            <div className="flex items-center">
              <hr className="w-1/4 dark:border-neutral-600" />
              <div className="ml-1 w-1/2">
                <FontAwesomeIcon
                  icon={faMessageSms}
                  className="opacity-40 dark:text-white"
                />
                <span className="ml-1 text-xs text-gray-500">
                  Check your messages
                </span>
              </div>
              <hr className="w-1/4 dark:border-neutral-600" />
            </div>
            <AuthCode
              disabled={isLoadingAuthCode}
              onAuthCodeChange={onAuthCodeChange}
              ref={authCodeRef}
              onResend={sendCode}
              resendSeconds={resendSeconds}
            />
          </section>
        )}
      </form>
    </AnimationContainer>
  );
};
