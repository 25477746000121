import { PropsWithChildren } from "react";

export const PosthogFeatureFlag = ({
  children,
  match,
  featureFlag,
}: PropsWithChildren<{
  match: string;
  featureFlag: string | null | undefined;
}>) => {
  if (
    featureFlag === match ||
    // for safety, fallback to displaying the control if feature flag returns as undefined from Posthog
    (match === "control" && featureFlag === undefined)
  ) {
    return <>{children}</>;
  }
};
